.app-loading-appear {
	opacity: 0.01;
}

.app-loading-appear.app-loading-appear-active {
	opacity: 1;
	transition: opacity 0.5s ease-in;
}

.app-loading-enter {
	opacity: 0.01;
}

.app-loading-enter.app-loading-enter-active {
	opacity: 1;
	transition: opacity 0.5s ease-in;
}

.app-loading-leave {
	opacity: 1;
}

.app-loading-leave.app-loading-leave-active {
	opacity: 0.01;
	transition: opacity 0.3s ease-in;
}

.nav-logo-loading {
	margin-top: 250px;
	width: 90%;
	max-width: 600px;
}

.loading-animation {
	-webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
	-moz-animation: fadein 1s; /* Firefox < 16 */
	-ms-animation: fadein 1s; /* Internet Explorer */
	-o-animation: fadein 1s; /* Opera < 12.1 */
	animation: fadein 1s;
}

@keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

/* Firefox < 16 */
@-moz-keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

/* Internet Explorer */
@-ms-keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

/* Opera < 12.1 */
@-o-keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
