.dygraph-container {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
}

.dygraph-legend {
	text-align: right !important;
	left: 0 !important;
	right: 65px !important;
	width: auto !important;
}

.dygraph-legend > span {
	display: none;
}

.dygraph-legend > span.highlight {
	display: inline;
}
