body {
	margin: 0;
	padding: 0;
	font-family: sans-serif;
	background: #20447e;
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none;
}

html,
body {
	height: 100%;
	width: 100%;
	position: fixed;
}

#root {
	height: 100%;
	width: 100%;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	overflow: hidden;
	position: fixed;
	background: #fff;
}

.statusbar-overlay {
	background: #20447e;
}
